import React from 'react';
import { Helmet } from 'react-helmet';

import SEO from '../components/seo';
import { Section } from '../components/page-elements';
import Container from '../components/container';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import BottomCut from '../components/bottomCut';

const HeadingText = {
  preHeader: '404: Not Found',
  title: 'Whoops! This is embarassing...',
  body: 'How about we try one of these?',
  primaryCTA: true,
  primaryCTAData: {
    label: 'Take me home',
    type: 'primary',
    arrow: true,
    path: '/',
  },
  secondaryCTA: true,
  secondaryCTAData: {
    label: 'Let me talk to someone',
    type: 'secondary',
    arrow: true,
    path: '/contact',
  },
};

const NotFoundPage = () => (
  <>
    <SEO title='404: Not found' />
    <Helmet>
      <body className='light-nav' />
    </Helmet>
    <Section light cuts='bottomCut'>
      <Container>
        <CenteredHeaderBlock data={HeadingText} />
      </Container>
      <BottomCut dark />
    </Section>
  </>
);

export default NotFoundPage;
